export default function($t) {
  return [

    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Stores.summary_StoreCode')
      },
      get title() {
        return $t('Stores.summary_StoreCode')
      }
    },

    {
      name: 'name',
      is_visible: true,
      headerTitle: 'true',
      get short_title() {
        return $t('Stores.type_Store')
      },
      get title() {
        return $t('Stores.type_Store')
      }
    },

    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('Stores.summary_StoreGroupName')
      },
      get title() {
        return $t('Stores.summary_StoreGroupName')
      }
    },

    {
      name: 'typeName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Stores.form_StoreType')
      },
      get title() {
        return $t('Stores.form_StoreType')
      }
    },

    {
      name: 'hasTransit',
      type: 'portal',
      is_visible: true,
      headerSubTitle: true,
      useCustomSubTitle: true,
      customSubTitle: $t('Stores.summary_StoreHasTransit_Description'),
      get short_title() {
        return $t('Stores.summary_TransitStore_Description')
      },
      get title() {
        return $t('Stores.summary_TransitStore_Description')
      }
    }
  ]
}
