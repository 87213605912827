import Stores from '../stores'
import Store from '../popup/view'
import FormStore from '../popup/store'
import StoreRemove from '../popup/remove'
import StoreGroups from '../store-groups'
import StoreGroupRemove from '../popup/remove-group'
import FormGroup from '../popup/group'

export default [
  {
    path: '/stores',
    name: 'Stores',
    component: Stores,
    meta: {
      main_menu: true,
      slug: 'stores',
      page: 'stores',
      category: 'Main_data',
      permissionKey: 'StoresMenu'
    },
    children: [
      {
        path: 'new',
        name: 'StoreNew',
        component: FormStore
      },
      {

        path: '/stores/store/:id',
        name: 'Store',
        component: Store
      },
      {
        path: ':id/edit',
        name: 'StoreEdit',
        component: FormStore,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'StoreRemove',
        component: StoreRemove
      }
    ]
  },
  {
    path: '/stores/groups',
    name: 'StoreGroups',
    component: StoreGroups,
    meta: {
      permissionKey: 'StoresMenu',
      page: 'stores'
    },
    children: [
      {
        path: 'new',
        name: 'StoreGroupNew',
        component: FormGroup
      },
      {
        path: ':id/edit',
        name: 'StoreGroupEdit',
        component: FormGroup,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'StoreGroupRemove',
        component: StoreGroupRemove
      }
    ]
  }
]
