<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('Stores.popupForm_Title_Edit') }}
      template(v-else) {{ $t('Stores.popupForm_Title_Create') }}

    template(slot="content")

      Loading(v-if="isLoadingStore")

      form.Form(
        v-else
        id              ="form-store"
        @submit.prevent ="onSubmitStoreForm"
      )

        div.Form-item.required.m-top-10
          label.Form-item-label {{ $t('Stores.popupForm_Field_Name') }}

          .control
            customTextInput(
              name="name"
              ref="firstField"
              :placeholder="$t('Stores.popupForm_Field_Name_Placeholder')"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('Stores.popupForm_Field_Name')"
              v-validate="'required|max:128|verify_name'"
              id="input-store-popup-field-name"
              v-model="form.name"
              :isDelayInput="true"
            )

            Button(
              size      ="medium"
              variant   ="icon loading",
              id        ="btn-store-popup-loading-name",
              tabindex  ="-1",
              v-show    ="isPendingName"
            )
              Loading(theme="disable")

            Button(
              size      ="medium"
              variant   ="icon check",
              id        ="btn-store-popup-ok-name",
              tabindex  ="-1",
              v-show    ="isNameInvalid"
              iconName  ="icon-check"
              iconClass ="icon-check"
              :justIcon ="true"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Stores.popupForm_Field_Code') }}

          .control
            customTextInput(
              name="code"
              :placeholder="$t('Stores.popupForm_Field_Code_Placeholder')"
              :error="veeErrors.has('code')"
              :data-vv-as="$t('Stores.popupForm_Field_Code')"
              v-validate="'required|verify_code|max:32'"
              id="input-store-popup-field-code"
              v-model="form.code"
              :isDelayInput="true"
            )

            Button(
              size      ="medium"
              variant   ="icon loading",
              id        ="btn-store-popup-loading-code",
              tabindex  ="-1",
              v-show    ="isPendingCode"
            )
              Loading(theme="disable")

            Button(
              size      ="medium"
              variant   ="icon check",
              id        ="btn-store-popup-ok-code",
              tabindex  ="-1",
              v-show    ="isCodeInvalid"
              iconName  ="icon-check"
              iconClass ="icon-check"
              :justIcon ="true"
            )

            showValidateError(
              v-show="veeErrors.has('code')"
              :errorName="veeErrors.first('code')"
            )
        div.Form-item.required
          label.Form-item-label {{ $t('Stores.popupForm_Field_StoreGroup') }}
          .control  
            custom-search(
            ref="secondaryInput"
            component-name="store-group-select"
            name="storeGroupSelect",
            v-model="form.storeGroupId",
            :data-vv-as="$t('Stores.popup_FormField_StoreGroupName')",
            v-validate="'required'",
            :inputClass="{ 'is-danger': veeErrors.has('storeGroupSelect') }",
            :on-search="getOptionsStoreGroup",
            :options="storeGroupSelectList",
            label="name",
            :searchAll="true"
            :withDebounce="false"
            :placeholder="$t('Stores.popup_FormField_StoreGroupName_Placeholder')"
            :loading="isLoadingStoreGroups"
            @input="onChangeStoreGroup"
            @resetOptions="resetStoreGroupOptions"
          )

          showValidateError(
            v-show="veeErrors.has('storeGroupSelect')"
            :errorName="veeErrors.first('storeGroupSelect')"
          )

        div.Form-item.required

          label.Form-item-label {{ $t('Stores.popupForm_Field_storeType') }}

          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="storeType",
              :optionData="storeTypeList"
              optionKey="value"
              optionIdKey="value"
              v-model="form.type"
              :selectedDataRequired="true"
              :data-vv-as="$t('Stores.form_StoreType')"
              :error="veeErrors.has('storeType')"
              :isValueNumber="true"
              v-validate="'required|is_not:-1'"
              id="select-stores-store-type"
              @input="changeType"
              :defaultText="$t('Stores.popupForm_Field_storeTypeSelect')"
            )

            showValidateError(
              v-show="veeErrors.has('storeType')"
              :errorName="veeErrors.first('storeType')"
            )

        div.Form-item.required

          label.Form-item-label {{ $t('Stores.popupForm_Field_inventoryClosingMethod') }}

          .control.form-item-select
            Icon(name="icon-down-arrow")
            
            customSelectInput(
              name="inventoryClosingMethod",
              :optionData="inventoryClosingMethods"
              optionKey="value"
              optionIdKey="value"
              :isValueNumber="true"
              :disabled="form.type === 3"
              v-model="form.inventoryClosingMethod"
              :data-vv-as="$t('Stores.popupForm_Field_inventoryClosingMethod')"
              :error="veeErrors.has('inventoryClosingMethod')"
              v-validate="'required|is_not:-1'"
              id="select-stores-store-closing-method"
              :defaultText="$t('Stores.popupForm_Field_inventoryClosingMethodSelect')"
              @input="checkClosingMethod"
            )

            showValidateError(
              v-show="veeErrors.has('inventoryClosingMethod')"
              :errorName="veeErrors.first('inventoryClosingMethod')"
            )
          
        .Form-item.name
          label.Form-item-label {{ $t('Global.currency') }}
          .control.form-item-select
            customSelect.name(
              name="currency"
              v-model="form.baseCurrencyId"
              :optionData="CurrencyList"
              optionTitle="name"
              selectedEmitValue="id"
              componentName="stores-currency-select"
              :isDefaultTextActive="false"
              :isDisabled="isEdit"
              :defaultTitle="$t('Register.formFieldCurrencySelect')"
              :data-vv-as="$t('Global.currency')"
              :isSearchActive="true"
              v-validate="'required'"
              :error="veeErrors.has('currency')"
            )

            showValidateError(
              v-show="veeErrors.has('currency')"
              :errorName="veeErrors.first('currency')"
            )

        div.Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_EmailAddress') }}

          .control
            customTextInput(
              name="emailAddress"
              :placeholder="$t('Stores.popupForm_Field_EmailAddress_Placeholder')"
              :error="veeErrors.has('emailAddress')"
              :data-vv-as="$t('Stores.popupForm_Field_EmailAddress')"
              v-validate="'email|max:128'"
              id="input-store-popup-field-email-address"
              v-model="form.emailAddress"
            )

            showValidateError(
              v-show="veeErrors.has('emailAddress')"
              :errorName="veeErrors.first('emailAddress')"
            )

        div.Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_Address') }}

          .address
            customTextInput(
              name="address"
              :placeholder="$t('Stores.popupForm_Field_Address_Placeholder')"
              :error="veeErrors.has('address')"
              :data-vv-as="$t('Stores.popupForm_Field_Address')"
              v-validate="'max:512'"
              id="input-store-popup-field-address"
              v-model="form.address"
            )
            showValidateError(
              v-show="veeErrors.has('address')"
              :errorName="veeErrors.first('address')"
            )

            CustomCheckbox.m-top-10(
              id="store-location-set`"
              :label="$t('Stores.formField_useMapForLocation')"
              v-model="useMap"
            )
        
            Modal.ListModal(
              :isOpen="useMap",
              @closeModal="useMap = false"
            )
              template(slot="title")
                | {{ $t('Stores.formField_selectLocation') }}
              template(slot="content")   
                GoogleMap(:apiKey="googleMapsApiKey" @change-address="getAddress" :language="userLangugeIsoCode" :autocomplete="true")
              template(slot="footer")  
                Button(
                primary
                size="large"
                variant="full"
                id="button-select-location-modal"
                @click="setAddress")
                  span {{ $t('Stores.selectAddress') }} 

        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_CountryName') }}

          .control
            customTextInput(
              name="countryName"
              :error="veeErrors.has('countryName')"
              id="input-store-popup-field-address-country-name"
              v-model="form.countryName"
            )
        
        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_City') }}

          .control
            customTextInput(
              name="administrativeAreaLevel1"
              :error="veeErrors.has('administrativeAreaLevel1')"
              id="input-store-popup-field-address-city"
              v-model="form.administrativeAreaLevel1"
            )
        
        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_District') }}

          .control
            customTextInput(
              name="administrativeAreaLevel2"
              :error="veeErrors.has('administrativeAreaLevel2')"
              id="input-store-popup-field-address-district"
              v-model="form.administrativeAreaLevel2"
            )
        
        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_PostalCode') }}

          .control
            customTextInput(
              name="postalCode"
              :error="veeErrors.has('postalCode')"
              id="input-store-popup-field-address-postal-code"
              v-model="form.postalCode"
            )
              

        div.Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_PhoneNumber') }}

          .control
            customTextInput(
              name="phoneNumber"
              :placeholder="$t('Stores.popupForm_Field_PhoneNumber_Placeholder')"
              :error="veeErrors.has('phoneNumber')"
              :data-vv-as="$t('Stores.popupForm_Field_PhoneNumber')"
              v-validate="'max:50'"
              id="input-store-popup-field-phone-number"
              v-model="form.phoneNumber"
            )

            showValidateError(
              v-show="veeErrors.has('phoneNumber')"
              :errorName="veeErrors.first('phoneNumber')"
            )

        div.Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_ContactInfo') }}

          .control
            customTextInput(
              name="contactInfo"
              :placeholder="$t('Stores.popupForm_Field_ContactInfo_Placeholder')"
              :error="veeErrors.has('contactInfo')"
              :data-vv-as="$t('Stores.popupForm_Field_ContactInfo')"
              v-validate="'max:128'"
              id="input-store-popup-field-contact-info"
              v-model="form.contactInfo"
            )

            showValidateError(
              v-show="veeErrors.has('contactInfo')"
              :errorName="veeErrors.first('contactInfo')"
            )
        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="checkbox-supplier-isEWayBillPayer"
              :label="$t('Stores.popupForm_Field_EWayBillPayer_Payer')"
              v-model="form.isEWayBillPayer"
            )
        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="input-store-popup-field-approval-transfer-permission"
              :label="$t('Stores.popupForm_Field_Approval_Transfer_Permission')"
              v-model="form.approvalTransferPermission")

        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="input-store-popup-field-has-transit"
              :label="$t('Stores.popupForm_Field_Has_Transit')"
              :disabled="form.type === 1 || form.type === 3"
              v-model="form.hasTransit")

        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="input-store-popup-field-can-product"
              :label="$t('Stores.popupForm_Field_Can_Production')"
              :disabled="form.type === 3"
              v-model="form.canProduction"
              @change="changeProductionStatus"
            )
        
        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="input-store-popup-field-can-product-on-transfer"
              :label="$t('Stores.popupForm_Field_Can_Production_On_Transfer')"
              :disabled="!form.canProduction"
              v-model="form.canProductionOnTransfer")

        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="input-store-popup-field-allow-negative-stock"
              :label="$t('Stores.popupForm_AllowNegativeStock')"
              v-model="form.allowNegativeStock")

        div.Form-item
          .control.flexbox
            CustomCheckbox(
              id="input-store-popup-field-expiration-date-control"
              :label="$t('Stores.popupForm_ExpirationDateControl')"
              :disabled="form.type !== 2 || form.inventoryClosingMethod === 2"
              :checked="form.expirationDateControlIsEnabled"
              @change="changeExpirationDateControl"
              )
            
            showValidateError.warning-expire-date(
              v-show="form.inventoryClosingMethod === 2"
              :errorName="$t('Stores.expDate_isNotSelectableWithThisClosingMethod')"
            )
    template(slot="footer")
      template(v-if="isEdit")

        Button(
          size      ="large"
          primary
          variant   ="full"
          type      ="submit"
          form      ="form-store"
          :disabled ="isLoading"
          :isLoading="isLoading"
        )
          span(v-show="!isLoading") {{ $t('Stores.popupForm_Button_Edit') }}

      template(v-else)

        Button(
          size="large"
          primary
          variant ="full"
          id      ="btn-store-popup-submit-new"
          type    ="submit"
          form    ="form-store"
          :disabled ="isLoading"
          :isLoading="isLoading"
        )
          span(v-show="!isLoading") {{ $t('Stores.popupForm_Button_Create') }}

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { omit, vueWaitLoader } from '@/utils/baseOperations'
import alertbox from '@/mixins/alertbox'
import customSelect from '@/view/global/custom-select'
import GoogleMap from '@/view/global/google-map'

export default {
  name: 'StoreForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  mixins: [
    alertbox()
  ],

  data () {
    return {
      form: {
        address: null,
        code: null,
        contactInfo: null,
        emailAddress: null,
        id: null,
        name: null,
        phoneNumber: null,
        storeGroupId: '-1',
        taxNumber: null,
        approvalTransferPermission: false,
        hasTransit: false,
        type: '-1',
        inventoryClosingMethod: '-1',
        canProduction: false,
        canProductionOnTransfer: false,
        allowNegativeStock: true,
        expirationDateControlIsEnabled: false,
        baseCurrencyId: null,
        countryCode: null,
        countryName: null,
        postalCode: null,
        isEWayBillPayer: false,
        administrativeAreaLevel1: null,
        administrativeAreaLevel2: null
      },
      storeGroupSelectList: [],
      useMap: false,
      userLangugeIsoCode: null,
      fullAddress: null,
      storeTypeList: [
        {
          value: 1,
          name: this.$t('Stores.type_Store')
        },
        {
          value: 2,
          name: this.$t('Stores.type_MainProductionStore')
        },
        {
          value: 3,
          name: this.$t('Stores.type_CostCenter')
        }
      ],
      inventoryClosingMethods: [
        {
          value: 1,
          name: this.$t('Stores.popupForm_resetUncounted')
        },
        {
          value: 2,
          name: this.$t('Stores.popupForm_handOverUncounted')
        }
      ]
    }
  },

  components: {
    customSelect,
    GoogleMap
  },

  created () {
    const self = this

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || "Something's wrong with store name"
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value,
            Id: self.$route.params.id
          }
          self.checkStoreName(params)
            .then(res => {
              if (res) {
                resolve({ valid: res.data.isAvailable })
              } else {
                resolve({ valid: false })
              }
            })
        })
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize({
      tr: {
        messages: {
          verify_name: field => 'Girdiğiniz ambar adı sistemde mevcut, Lütfen farklı bir ambar adı giriniz.'
        }
      }
    })

    const verifyCode = {
      getMessage (field, params, data) {
        return (data && data.message) || "Something's wrong with store code"
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Code: value,
            Id: self.$route.params.id
          }
          self.checkStoreCode(params)
            .then(res => {
              if (res) {
                resolve({ valid: res.data.isAvailable })
              } else {
                resolve({ valid: false })
              }
            })
        })
      }
    }

    Validator.extend('verify_code', verifyCode)
    Validator.localize({
      tr: {
        messages: {
          verify_code: field => 'Girdiğiniz ambar kodu sistemde mevcut, Lütfen farklı bir ambar kodu giriniz.'
        }
      }
    })
  },

  mounted () {
    if (this.isEdit) this.init()
    this.formFocus()
    this.getStoreGroups()
    this.userLangugeIsoCode = this.Languages.find(i => i.id = this.UserInfo.languageId).isoCode.split('-')[0]
  },

  updated () {
    if (!this.form.id && this.store) {
      // Don't override falsy values. This is to save -1 values of dropdowns
      const storeWithNotEmptyFields = Object.keys(this.store).reduce((store, key) => {
        if (this.store[key] != null) {
          return Object.assign({}, store, {
            [key]: this.store[key]
          })
        }
        return store
      }, {})

      this.form = Object.assign({}, this.form, storeWithNotEmptyFields)
    }
  },

  beforeDestroy () {
    this.resetStore()
  },

  computed: {
    ...mapGetters('Stores', [
      'store'
    ]),

    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    ...mapGetters('Global', [
      'CurrencyList',
      'User',
      'Languages',
      'findLanguage'
    ]),

     ...mapGetters('Settings', [
      'UserInfo'
    ]),

    ...mapFields({
      flagsName: 'name',
      flagsCode: 'code'
    }),

    isLoading () {
      return this.$wait.is(['updateStore', 'createStore', 'storesHeaderSubmit'])
    },

    isLoadingStoreGroups () {
      return this.$wait.is(['getStoreGroupList'])
    },

    googleMapsApiKey () {
      return window.VUE_APP_GOOGLE_MAPS_API_KEY || process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    },

    isLoadingStore () {
      return this.$wait.is(['getStore'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isNameInvalid () {
      const isValid = this.flagsName.valid && !this.isPendingName
      if (!this.flagsName.required) {
        return isValid && !!this.form.name
      }
      return isValid
    },

    isCodeInvalid () {
      const isValid = this.flagsCode.valid && !this.isPendingCode
      if (!this.flagsCode.required) {
        return isValid && !!this.form.code
      }
      return isValid
    },

    isPendingCode () {
      return this.flagsCode.pending
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stores', [
      'getStoreList',
      'getStore',
      'createStore',
      'updateStore',
      'deleteStore',
      'checkStoreName',
      'checkStoreCode'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    ...mapMutations('Stores', {
      resetStore: 'RESET_STORE'
    }),

    ...mapActions('Transfer', [
      'checkTranferTransit'
    ]),

    ...mapActions('Global', [
      'getCurrencyList'
    ]),


    getStoreGroups (search) {
      this.getStoreGroupList({name: search, pageNumber: 1, pageSize: 99999}).then(res => {
        if (res) this.storeGroupSelectList = res.data.storeGroupList
      })
    },

    getOptionsStoreGroup (search) {
      this.getStoreGroups(search)
    },

    resetStoreGroupOptions () {
      this.storeGroupSelectList = []
      this.getOptionsStoreGroup('')
    },

    checkClosingMethod () {
      if (this.form.inventoryClosingMethod === 2) this.form.expirationDateControlIsEnabled = false
    },

    init () {
      const storeId = this.$route.params.id
      this.getStore(storeId).then(res => {
        this.form.storeGroupId = {
          id: res.data.store.storeGroupId,
          name: res.data.store.storeGroupName,
        }
      })
    },

    changeExpirationDateControl () {
      this.form.expirationDateControlIsEnabled = !this.form.expirationDateControlIsEnabled
    },

    changeProductionStatus () {
      if (!this.form.canProduction) this.form.canProductionOnTransfer = false
    }, 

    getAddress (data) {
      this.fullAddress = data.address
      this.form.country = data.info.address_components.find(i => i.types[0] === 'country').short_name
      this.form.countryName = data.info.address_components.find(i => i.types[0] === 'country').long_name
      this.form.administrativeAreaLevel1 = data.info.address_components.find(i => i.types[0] === 'administrative_area_level_1').short_name
      this.form.administrativeAreaLevel2 = data.info.address_components.find(i => i.types[0] === 'administrative_area_level_2').short_name
      this.form.postalCode = data.info.address_components.find(i => i.types[0] === 'postal_code').short_name
    },

    setAddress () {
      this.useMap = false
      this.form.address = this.fullAddress
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onChangeStoreGroup (item) {
      this.storeGroupSelectList.find(i => {
        if (i.id === item.id) this.form.baseCurrencyId = i.baseCurrencyId
      })
    },

    changeType (val) {
      if (val === 1 || val === 3) {
        this.form.hasTransit = false
        this.form.expirationDateControlIsEnabled = false
      }
      if (val === 3) {
        this.form.canProduction = false
        this.form.canProductionOnTransfer = false
        this.form.inventoryClosingMethod = 1
      }
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    transitTypeAlert () {
      const title = this.$t('Stores.hasTransitTitle')
      const message = this.$t('Stores.hasTransitDesc')
      const confirmButtonText = this.$t('Stores.hasTransitConfirmButton')

      this.alert({
        title,
        message,
        confirm: {
          text: confirmButtonText,
          action: async (close) => {
            close()
            await this.submitEdit()
          }
        }
      })
    },

    async checkTransitStore () {
      if (this.isEdit && !this.form.hasTransit) {
        await this.checkTranferTransit({ StoreId: this.$route.params.id })
          .then(async res => {
            res.data.hasTransitTransfer ? this.transitTypeAlert() : await this.submitEdit()
          })
      } else await this.submitEdit()
    },

    onSubmitStoreForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        // form error
        if (!result) return
        if (this.isEdit) await this.checkTransitStore()
        else await this.submitNew()
      })
    }, 'storesHeaderSubmit'),

    async submitNew () {
      let payload = omit(this.form, ['id'])
      payload.storeGroupId = this.form.storeGroupId.id
      await this.createStore(payload).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: payload.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    },

    async submitEdit () {
      let payload = omit(this.form, ['storeGroupName'])
      payload.storeGroupId = this.form.storeGroupId.id
      await this.updateStore(payload).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: payload.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

:deep(.inputfield) {
  label {
    white-space: nowrap;
  }
}
.icon-down-arrow {
  width: 12px !important;
}

:deep(.select-dropdown-custom .select-dropdown-custom-name:first-child) {
  font-size: $font-size-normal;
  color: $color-dark;
}
:deep(.select-dropdown-custom-selected) {
  width: 100% !important;
}
.warning-expire-date {
  position: absolute;
  top: 20px;
}
.address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
