<template lang="pug">
DeleteAlert(
    :item="item",
    :fields="fields",
    :tableName="$t('Stores.remove_TableName')",
    loading="Stores/deleteStore",
    @delegateOnAlertRemoveOk="itemRemove"
  )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RemoveStore',

  data () {
    return {
      item: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Stores.remove_TableColumn_Name_Short'),
        title: this.$t('Stores.remove_TableColumn_Name')
      }, {
        name: 'code',
        is_visible: true,
        short_title: this.$t('Stores.remove_TableColumn_Code_Short'),
        title: this.$t('Stores.remove_TableColumn_Code')
      }, {
        name: 'storeGroupName',
        is_visible: true,
        short_title: this.$t('Stores.remove_TableColumn_StoreGroup_Short'),
        title: this.$t('Stores.remove_TableColumn_StoreGroup')
      }]
    }
  },

  computed: {
    ...mapGetters('Stores', [
      'store'
    ])
  },

  mounted () {
    const item = this.$route.params.item
    if (item) {
      this.item = item
    } else {
      const storeId = this.$route.params.id
      this.getStore(storeId)
        .then(res => {
          this.item = res.data.store
        })
    }
  },

  watch: {
    store () {
      this.item = this.store
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stores', [
      'getStore',
      'getStoreList',
      'deleteStore'
    ]),

    ...mapMutations('Stores', [
      'RESET_STORE'
    ]),

    close () {
      this.RESET_STORE()
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteStore(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'storesRemoveHeader')
  }
}
</script>

<style lang="scss">
</style>
