<template lang="pug">
DeleteAlert(
    :item="item",
    :fields="fields",
    :tableName="$t('StoreGroups.remove_TableName')",
    loading="StoreGroups/delete_STOREGROUP_ITEM",
    @delegateOnAlertRemoveOk="itemRemove"
  )
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'GroupRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('StoreGroups.remove_TableColumn_Name_Short'),
        title: this.$t('StoreGroups.remove_TableColumn_Name')
      }],
      item: {}
    }
  },

  async mounted () {
    this.item = this.$route.params.item
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getStoreGroup(this.$route.params.id)
        .then(res => {
          this.item = res.data.storeGroup
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList',
      'deleteStoreGroup',
      'getStoreGroup'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteStoreGroup(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.getStoreGroupList({pageNumber: 1, pageSize: 9999})
          this.close()
        }
      })
    }, 'storesGroupRemoveHeader')
  }
}
</script>

<style lang="scss">
</style>
