import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Store, Stores, MiniStores } from '../model'
import { SortObject } from '@/utils/sorted'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    StoreList: new Stores(),
    SearchStoreList: new Stores(),
    MiniStoreList: new MiniStores(),
    Store: null,
    pageHeaderLinks: [
      'Stores',
      'StoreGroups'
    ],
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    storeList: state => state.StoreList.list,
    isStoresEmpty: state => state.StoreList.length === 0,
    miniStoreList: state => state.MiniStoreList.list,
    searchStoreList: state => state.SearchStoreList.list,
    sortedStoreList: state => SortObject([...state.StoreList.list], { order: 'asc', by: 'name' }),
    store: state => state.Store,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getStoreList({ commit, dispatch, state }, payload) {
      payload.pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const loadingName = 'getStoreList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Store/all', {
            params: { ...payload }
          }) 
        },
        success: result => {
          commit('SET_STORE_LIST', result.data)
          commit('SET_STORE_CAN_PRODUCT_LIST', result.data.storeList)
          return result
        }
      })
      return results
    },

    async getMiniStoreList({ commit, dispatch }) {
      const loadingName = 'getMiniStoreList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('store/searchby/name')
        },
        success: result => {
          commit('SET_MINI_STORE_LIST', result.data.items)
          return result
        }
      })
      return results
    },

    async fetchStoreByName({ commit }, params) {
      const loadingName = 'fetchStoreByName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Store/searchby/name', {params})
        },
        success: result => {
          commit('SET_SEARCH_STORE_LIST', result.data.items)
          return result
        }
      })
      return results
    },

    async getStore({ commit, dispatch }, Id) {
      const loadingName = 'getStore'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(`Store?Id=${Id}`)
        },
        success: result => {
          commit('SET_STORE', result.data.store)
          return result
        }
      })
      return results
    },

    async updateStore({ commit, dispatch }, store) {
      const loadingName = 'editStore'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Store', store)
        },
        success: result => result
      })
      return results
    },

    async createStore({ commit, dispatch }, store) {
      const loadingName = 'createStore'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Store', store)
        },
        success: result => result
      })
      return results
    },

    async deleteStore({ commit, dispatch }, Id) {
      const loadingName = 'deleteStore'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Store', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async checkStoreName({ commit, dispatch }, params) {
      const loadingName = 'checkStoreName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Store/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async checkStoreCode({ commit, dispatch }, params) {
      const loadingName = 'checkStoreCode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Store/check/code', { params })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.StoreList = new Stores()
      state.MiniStoreList = new MiniStores()
      state.Store = null
      state.Page.number = 1
      state.Page.size = 20
      state.Page.total = 0
    },

    SET_STORE_LIST(state, data) {
      state.StoreList.addItems(data.storeList)
      state.Page.number = data.pageNumber
      state.Page.total = data.totalCount
      state.Page.size = data.pageSize
    },

    SET_STORE_CAN_PRODUCT_LIST(state, list) {
      state.StoreList.addItems(list)
    },

    SET_MINI_STORE_LIST(state, list) {
      state.MiniStoreList.addItems(list)
    },

    SET_STORE(state, store) {
      state.Store = new Store(store)
    },

    SET_SEARCH_STORE_LIST(state, list) {
      state.SearchStoreList.addItems(list)
    },

    RESET_STORE(state) {
      state.Store = null
    }
  }
}
