<template lang="pug">
PopupView(
  :item="item",
  :fields="fields"
  :hyphen="true"
  @close="closePopup")
    template(slot="title") {{ item.name }}
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'StockView',

  data () {
    return {
      item: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_Name_Short'),
        title: this.$t('Stores.popupForm_Field_Name')
      }, {
        name: 'code',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_Code_Short'),
        title: this.$t('Stores.popupForm_Field_Code')
      }, {
        name: 'storeGroupName',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_StoreGroup_Short'),
        title: this.$t('Stores.popupForm_Field_StoreGroup')
      }, {
        name: 'taxNumber',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_TaxNumber_Short'),
        title: this.$t('Stores.popupForm_Field_TaxNumber')
      }, {
        name: 'emailAddress',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_EmailAddress_Short'),
        title: this.$t('Stores.popupForm_Field_EmailAddress')
      }, {
        name: 'address',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_Address_Short'),
        title: this.$t('Stores.popupForm_Field_Address')
      }, {
        name: 'phoneNumber',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_PhoneNumber_Short'),
        title: this.$t('Stores.popupForm_Field_PhoneNumber')
      }, {
        name: 'contactInfo',
        is_visible: true,
        short_title: this.$t('Stores.popupForm_Field_ContactInfo_Short'),
        title: this.$t('Stores.popupForm_Field_ContactInfo')
      }]
    }
  },

  computed: {
    ...mapGetters('Stores', [
      'store'
    ])
  },

  mounted () {
    const item = this.$route.params.item
    if (item) {
      this.item = item
    } else {
      const storeId = this.$route.params.id
      this.getStore(storeId)
    }
  },

  watch: {
    store () {
      this.item = this.store
    }
  },

  methods: {
    ...mapActions('Stores', [
      'getStore'
    ]),

    ...mapMutations('Stores', [
      'RESET_STORE'
    ]),

    closePopup () {
      this.RESET_STORE()
    }
  }
}
</script>
