import BaseClass from '@/utils/baseclass'

export class Stores extends BaseClass {
  addItem(item) {
    let newItem = new Store(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.storeGroupName = item.storeGroupName
      findItem.name = item.name
      findItem.code = item.code
      findItem.address = item.address
      findItem.contactInfo = item.contactInfo
      findItem.emailAddress = item.emailAddress
      findItem.phoneNumber = item.phoneNumber
      findItem.storeGroupId = item.storeGroupId
      findItem.taxNumber = item.taxNumber
      findItem.approvalTransferPermission = item.approvalTransferPermission
      findItem.hasTransit = item.hasTransit
      findItem.inventoryClosingMethod = item.inventoryClosingMethod
      findItem.allowNegativeStock = item.allowNegativeStock
      findItem.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled
      findItem.baseCurrencyId = item.baseCurrencyId
      findItem.country = item.country
      findItem.administrativeAreaLevel1 = item.administrativeAreaLevel1
      findItem.administrativeAreaLevel2 = item.administrativeAreaLevel2
      findItem.countryName = item.countryName
      findItem.postalCode = item.postalCode
      findItem.isEWayBillPayer = item.isEWayBillPayer
    }
  }
}

export class Store {
  constructor(item) {
    this.id = item.id
    this.storeGroupName = item.storeGroupName
    this.name = item.name
    this.code = item.code
    this.address = item.address
    this.contactInfo = item.contactInfo
    this.emailAddress = item.emailAddress
    this.phoneNumber = item.phoneNumber
    this.storeGroupId = item.storeGroupId
    this.taxNumber = item.taxNumber
    this.approvalTransferPermission = item.approvalTransferPermission
    this.hasTransit = item.hasTransit
    this.type = item.type
    this.canProduction = item.canProduction
    this.canProductionOnTransfer = item.canProductionOnTransfer
    this.inventoryClosingMethod = item.inventoryClosingMethod
    this.allowNegativeStock = item.allowNegativeStock
    this.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled
    this.baseCurrencyId = item.baseCurrencyId
    this.country = item.country
    this.administrativeAreaLevel1 = item.administrativeAreaLevel1
    this.administrativeAreaLevel2 = item.administrativeAreaLevel2
    this.countryName = item.countryName
    this.postalCode = item.postalCode
    this.isEWayBillPayer = item.isEWayBillPayer
  }
}

export class MiniStores extends BaseClass {
  addItem(item) {
    let newItem = new MiniStore(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
    }
  }
}

class MiniStore {
  constructor(item) {
    this.id = item.id
    this.name = item.name
  }
}

export class StoreGroups extends BaseClass {
  addItem(item) {
    let newItem = new StoreGroup(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
      findItem.code = item.code
      findItem.address = item.address
      findItem.taxNumber = item.taxNumber
      findItem.timeZoneKey = item.timeZoneKey
      findItem.baseCurrencyId = item.baseCurrencyId
      findItem.country = item.country
      findItem.administrativeAreaLevel1 = item.administrativeAreaLevel1
      findItem.administrativeAreaLevel2 = item.administrativeAreaLevel2
      findItem.countryName = item.countryName
      findItem.postalCode = item.postalCode
      findItem.taxOfficeName = item.taxOfficeName
    }
  }
}

class StoreGroup {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.code = item.code
    this.propertyKey = item.propertyKey
    this.taxNumber = item.taxNumber
    this.timeZoneKey = item.timeZoneKey
    this.baseCurrencyId = item.baseCurrencyId
    this.country = item.country
    this.administrativeAreaLevel1 = item.administrativeAreaLevel1
    this.administrativeAreaLevel2 = item.administrativeAreaLevel2
    this.countryName = item.countryName
    this.postalCode = item.postalCode
    this.taxOfficeName = item.taxOfficeName
    this.address = item.address
  }
}
