var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Stores.popupForm_Title_Edit")))]
            : [_vm._v(_vm._s(_vm.$t("Stores.popupForm_Title_Create")))],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingStore
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-store" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitStoreForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required m-top-10" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stores.popupForm_Field_Name"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:128|verify_name",
                              expression: "'required|max:128|verify_name'",
                            },
                          ],
                          ref: "firstField",
                          attrs: {
                            name: "name",
                            placeholder: _vm.$t(
                              "Stores.popupForm_Field_Name_Placeholder"
                            ),
                            error: _vm.veeErrors.has("name"),
                            "data-vv-as": _vm.$t("Stores.popupForm_Field_Name"),
                            id: "input-store-popup-field-name",
                            isDelayInput: true,
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                        _c(
                          "Button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isPendingName,
                                expression: "isPendingName",
                              },
                            ],
                            attrs: {
                              size: "medium",
                              variant: "icon loading",
                              id: "btn-store-popup-loading-name",
                              tabindex: "-1",
                            },
                          },
                          [_c("Loading", { attrs: { theme: "disable" } })],
                          1
                        ),
                        _c("Button", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isNameInvalid,
                              expression: "isNameInvalid",
                            },
                          ],
                          attrs: {
                            size: "medium",
                            variant: "icon check",
                            id: "btn-store-popup-ok-name",
                            tabindex: "-1",
                            iconName: "icon-check",
                            iconClass: "icon-check",
                            justIcon: true,
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("name"),
                              expression: "veeErrors.has('name')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("name") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stores.popupForm_Field_Code"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|verify_code|max:32",
                              expression: "'required|verify_code|max:32'",
                            },
                          ],
                          attrs: {
                            name: "code",
                            placeholder: _vm.$t(
                              "Stores.popupForm_Field_Code_Placeholder"
                            ),
                            error: _vm.veeErrors.has("code"),
                            "data-vv-as": _vm.$t("Stores.popupForm_Field_Code"),
                            id: "input-store-popup-field-code",
                            isDelayInput: true,
                          },
                          model: {
                            value: _vm.form.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "code", $$v)
                            },
                            expression: "form.code",
                          },
                        }),
                        _c(
                          "Button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isPendingCode,
                                expression: "isPendingCode",
                              },
                            ],
                            attrs: {
                              size: "medium",
                              variant: "icon loading",
                              id: "btn-store-popup-loading-code",
                              tabindex: "-1",
                            },
                          },
                          [_c("Loading", { attrs: { theme: "disable" } })],
                          1
                        ),
                        _c("Button", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isCodeInvalid,
                              expression: "isCodeInvalid",
                            },
                          ],
                          attrs: {
                            size: "medium",
                            variant: "icon check",
                            id: "btn-store-popup-ok-code",
                            tabindex: "-1",
                            iconName: "icon-check",
                            iconClass: "icon-check",
                            justIcon: true,
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("code"),
                              expression: "veeErrors.has('code')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("code") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stores.popupForm_Field_StoreGroup"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "secondaryInput",
                            attrs: {
                              "component-name": "store-group-select",
                              name: "storeGroupSelect",
                              "data-vv-as": _vm.$t(
                                "Stores.popup_FormField_StoreGroupName"
                              ),
                              inputClass: {
                                "is-danger":
                                  _vm.veeErrors.has("storeGroupSelect"),
                              },
                              "on-search": _vm.getOptionsStoreGroup,
                              options: _vm.storeGroupSelectList,
                              label: "name",
                              searchAll: true,
                              withDebounce: false,
                              placeholder: _vm.$t(
                                "Stores.popup_FormField_StoreGroupName_Placeholder"
                              ),
                              loading: _vm.isLoadingStoreGroups,
                            },
                            on: {
                              input: _vm.onChangeStoreGroup,
                              resetOptions: _vm.resetStoreGroupOptions,
                            },
                            model: {
                              value: _vm.form.storeGroupId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeGroupId", $$v)
                              },
                              expression: "form.storeGroupId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("storeGroupSelect"),
                            expression: "veeErrors.has('storeGroupSelect')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("storeGroupSelect"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stores.popupForm_Field_storeType"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "storeType",
                            optionData: _vm.storeTypeList,
                            optionKey: "value",
                            optionIdKey: "value",
                            selectedDataRequired: true,
                            "data-vv-as": _vm.$t("Stores.form_StoreType"),
                            error: _vm.veeErrors.has("storeType"),
                            isValueNumber: true,
                            id: "select-stores-store-type",
                            defaultText: _vm.$t(
                              "Stores.popupForm_Field_storeTypeSelect"
                            ),
                          },
                          on: { input: _vm.changeType },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("storeType"),
                              expression: "veeErrors.has('storeType')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("storeType"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Stores.popupForm_Field_inventoryClosingMethod"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "inventoryClosingMethod",
                            optionData: _vm.inventoryClosingMethods,
                            optionKey: "value",
                            optionIdKey: "value",
                            isValueNumber: true,
                            disabled: _vm.form.type === 3,
                            "data-vv-as": _vm.$t(
                              "Stores.popupForm_Field_inventoryClosingMethod"
                            ),
                            error: _vm.veeErrors.has("inventoryClosingMethod"),
                            id: "select-stores-store-closing-method",
                            defaultText: _vm.$t(
                              "Stores.popupForm_Field_inventoryClosingMethodSelect"
                            ),
                          },
                          on: { input: _vm.checkClosingMethod },
                          model: {
                            value: _vm.form.inventoryClosingMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "inventoryClosingMethod", $$v)
                            },
                            expression: "form.inventoryClosingMethod",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has(
                                "inventoryClosingMethod"
                              ),
                              expression:
                                "veeErrors.has('inventoryClosingMethod')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first(
                              "inventoryClosingMethod"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item name" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Global.currency"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("customSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "name",
                          attrs: {
                            name: "currency",
                            optionData: _vm.CurrencyList,
                            optionTitle: "name",
                            selectedEmitValue: "id",
                            componentName: "stores-currency-select",
                            isDefaultTextActive: false,
                            isDisabled: _vm.isEdit,
                            defaultTitle: _vm.$t(
                              "Register.formFieldCurrencySelect"
                            ),
                            "data-vv-as": _vm.$t("Global.currency"),
                            isSearchActive: true,
                            error: _vm.veeErrors.has("currency"),
                          },
                          model: {
                            value: _vm.form.baseCurrencyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "baseCurrencyId", $$v)
                            },
                            expression: "form.baseCurrencyId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("currency"),
                              expression: "veeErrors.has('currency')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("currency") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stores.popupForm_Field_EmailAddress"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "email|max:128",
                              expression: "'email|max:128'",
                            },
                          ],
                          attrs: {
                            name: "emailAddress",
                            placeholder: _vm.$t(
                              "Stores.popupForm_Field_EmailAddress_Placeholder"
                            ),
                            error: _vm.veeErrors.has("emailAddress"),
                            "data-vv-as": _vm.$t(
                              "Stores.popupForm_Field_EmailAddress"
                            ),
                            id: "input-store-popup-field-email-address",
                          },
                          model: {
                            value: _vm.form.emailAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "emailAddress", $$v)
                            },
                            expression: "form.emailAddress",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("emailAddress"),
                              expression: "veeErrors.has('emailAddress')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("emailAddress"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stores.popupForm_Field_Address"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "address" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:512",
                              expression: "'max:512'",
                            },
                          ],
                          attrs: {
                            name: "address",
                            placeholder: _vm.$t(
                              "Stores.popupForm_Field_Address_Placeholder"
                            ),
                            error: _vm.veeErrors.has("address"),
                            "data-vv-as": _vm.$t(
                              "Stores.popupForm_Field_Address"
                            ),
                            id: "input-store-popup-field-address",
                          },
                          model: {
                            value: _vm.form.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "address", $$v)
                            },
                            expression: "form.address",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("address"),
                              expression: "veeErrors.has('address')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("address") },
                        }),
                        _c("CustomCheckbox", {
                          staticClass: "m-top-10",
                          attrs: {
                            id: "store-location-set`",
                            label: _vm.$t("Stores.formField_useMapForLocation"),
                          },
                          model: {
                            value: _vm.useMap,
                            callback: function ($$v) {
                              _vm.useMap = $$v
                            },
                            expression: "useMap",
                          },
                        }),
                        _c(
                          "Modal",
                          {
                            staticClass: "ListModal",
                            attrs: { isOpen: _vm.useMap },
                            on: {
                              closeModal: function ($event) {
                                _vm.useMap = false
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("Stores.formField_selectLocation")
                                )
                              ),
                            ]),
                            _c(
                              "template",
                              { slot: "content" },
                              [
                                _c("GoogleMap", {
                                  attrs: {
                                    apiKey: _vm.googleMapsApiKey,
                                    language: _vm.userLangugeIsoCode,
                                    autocomplete: true,
                                  },
                                  on: { "change-address": _vm.getAddress },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "template",
                              { slot: "footer" },
                              [
                                _c(
                                  "Button",
                                  {
                                    attrs: {
                                      primary: "",
                                      size: "large",
                                      variant: "full",
                                      id: "button-select-location-modal",
                                    },
                                    on: { click: _vm.setAddress },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("Stores.selectAddress")) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stores.popupForm_Field_CountryName"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          attrs: {
                            name: "countryName",
                            error: _vm.veeErrors.has("countryName"),
                            id: "input-store-popup-field-address-country-name",
                          },
                          model: {
                            value: _vm.form.countryName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "countryName", $$v)
                            },
                            expression: "form.countryName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stores.popupForm_Field_City"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          attrs: {
                            name: "administrativeAreaLevel1",
                            error: _vm.veeErrors.has(
                              "administrativeAreaLevel1"
                            ),
                            id: "input-store-popup-field-address-city",
                          },
                          model: {
                            value: _vm.form.administrativeAreaLevel1,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "administrativeAreaLevel1",
                                $$v
                              )
                            },
                            expression: "form.administrativeAreaLevel1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stores.popupForm_Field_District"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          attrs: {
                            name: "administrativeAreaLevel2",
                            error: _vm.veeErrors.has(
                              "administrativeAreaLevel2"
                            ),
                            id: "input-store-popup-field-address-district",
                          },
                          model: {
                            value: _vm.form.administrativeAreaLevel2,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "administrativeAreaLevel2",
                                $$v
                              )
                            },
                            expression: "form.administrativeAreaLevel2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stores.popupForm_Field_PostalCode"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          attrs: {
                            name: "postalCode",
                            error: _vm.veeErrors.has("postalCode"),
                            id: "input-store-popup-field-address-postal-code",
                          },
                          model: {
                            value: _vm.form.postalCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "postalCode", $$v)
                            },
                            expression: "form.postalCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stores.popupForm_Field_PhoneNumber"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:50",
                              expression: "'max:50'",
                            },
                          ],
                          attrs: {
                            name: "phoneNumber",
                            placeholder: _vm.$t(
                              "Stores.popupForm_Field_PhoneNumber_Placeholder"
                            ),
                            error: _vm.veeErrors.has("phoneNumber"),
                            "data-vv-as": _vm.$t(
                              "Stores.popupForm_Field_PhoneNumber"
                            ),
                            id: "input-store-popup-field-phone-number",
                          },
                          model: {
                            value: _vm.form.phoneNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phoneNumber", $$v)
                            },
                            expression: "form.phoneNumber",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("phoneNumber"),
                              expression: "veeErrors.has('phoneNumber')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("phoneNumber"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stores.popupForm_Field_ContactInfo"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:128",
                              expression: "'max:128'",
                            },
                          ],
                          attrs: {
                            name: "contactInfo",
                            placeholder: _vm.$t(
                              "Stores.popupForm_Field_ContactInfo_Placeholder"
                            ),
                            error: _vm.veeErrors.has("contactInfo"),
                            "data-vv-as": _vm.$t(
                              "Stores.popupForm_Field_ContactInfo"
                            ),
                            id: "input-store-popup-field-contact-info",
                          },
                          model: {
                            value: _vm.form.contactInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "contactInfo", $$v)
                            },
                            expression: "form.contactInfo",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("contactInfo"),
                              expression: "veeErrors.has('contactInfo')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("contactInfo"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "checkbox-supplier-isEWayBillPayer",
                            label: _vm.$t(
                              "Stores.popupForm_Field_EWayBillPayer_Payer"
                            ),
                          },
                          model: {
                            value: _vm.form.isEWayBillPayer,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isEWayBillPayer", $$v)
                            },
                            expression: "form.isEWayBillPayer",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "input-store-popup-field-approval-transfer-permission",
                            label: _vm.$t(
                              "Stores.popupForm_Field_Approval_Transfer_Permission"
                            ),
                          },
                          model: {
                            value: _vm.form.approvalTransferPermission,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "approvalTransferPermission",
                                $$v
                              )
                            },
                            expression: "form.approvalTransferPermission",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "input-store-popup-field-has-transit",
                            label: _vm.$t("Stores.popupForm_Field_Has_Transit"),
                            disabled:
                              _vm.form.type === 1 || _vm.form.type === 3,
                          },
                          model: {
                            value: _vm.form.hasTransit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "hasTransit", $$v)
                            },
                            expression: "form.hasTransit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "input-store-popup-field-can-product",
                            label: _vm.$t(
                              "Stores.popupForm_Field_Can_Production"
                            ),
                            disabled: _vm.form.type === 3,
                          },
                          on: { change: _vm.changeProductionStatus },
                          model: {
                            value: _vm.form.canProduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "canProduction", $$v)
                            },
                            expression: "form.canProduction",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "input-store-popup-field-can-product-on-transfer",
                            label: _vm.$t(
                              "Stores.popupForm_Field_Can_Production_On_Transfer"
                            ),
                            disabled: !_vm.form.canProduction,
                          },
                          model: {
                            value: _vm.form.canProductionOnTransfer,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "canProductionOnTransfer", $$v)
                            },
                            expression: "form.canProductionOnTransfer",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "input-store-popup-field-allow-negative-stock",
                            label: _vm.$t(
                              "Stores.popupForm_AllowNegativeStock"
                            ),
                          },
                          model: {
                            value: _vm.form.allowNegativeStock,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "allowNegativeStock", $$v)
                            },
                            expression: "form.allowNegativeStock",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "div",
                      { staticClass: "control flexbox" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "input-store-popup-field-expiration-date-control",
                            label: _vm.$t(
                              "Stores.popupForm_ExpirationDateControl"
                            ),
                            disabled:
                              _vm.form.type !== 2 ||
                              _vm.form.inventoryClosingMethod === 2,
                            checked: _vm.form.expirationDateControlIsEnabled,
                          },
                          on: { change: _vm.changeExpirationDateControl },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.inventoryClosingMethod === 2,
                              expression: "form.inventoryClosingMethod === 2",
                            },
                          ],
                          staticClass: "warning-expire-date",
                          attrs: {
                            errorName: _vm.$t(
                              "Stores.expDate_isNotSelectableWithThisClosingMethod"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.isEdit
            ? [
                _c(
                  "Button",
                  {
                    attrs: {
                      size: "large",
                      primary: "",
                      variant: "full",
                      type: "submit",
                      form: "form-store",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.$t("Stores.popupForm_Button_Edit")))]
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "Button",
                  {
                    attrs: {
                      size: "large",
                      primary: "",
                      variant: "full",
                      id: "btn-store-popup-submit-new",
                      type: "submit",
                      form: "form-store",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.$t("Stores.popupForm_Button_Create")))]
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }