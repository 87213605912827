<template lang="pug">
Popup

    template(
      slot="title"
    )

      template(
        v-if="isEdit"
      ) {{ $t('StoreGroups.popupForm_Title_Edit') }}

      template(
        v-else
      ) {{ $t('StoreGroups.popupForm_Title_Create') }}

    template(
      slot="content"
    )

      form.Form(
        id="form-store-group"
        @submit.prevent="onSubmitStoreGroupForm"
      )

        div.Form-item.required

          label.Form-item-label {{ $t('StoreGroups.popupForm_Field_Name') }}

          .control

            customTextInput(
              name="name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('StoreGroups.popupForm_Field_Name')"
              v-validate="'required|max:128|verify_name'"
              id="input-store-groups-popup-field-name"
              v-model="item.name"
              :isDelayInput="true"
            )

            Button(
              variant="icon loading",
              id="btn-store-groups-popup-loading-name",
              tabindex="-1",
              v-show="isPendingName"
            )
              Loading(theme="disable")

            Button(
              variant="icon check",
              id="btn-store-groups-popup-ok-name",
              tabindex="-1",
              v-show="isValidateName"
              iconName="icon-check"
              iconClass="icon-check"
              :justıcon="true"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
              id="validate-store-groups-name-message"
            )

        div.Form-item

          label.Form-item-label {{ $t('StoreGroups.popupForm_Field_Code') }}

          .control

            customTextInput(
              name="code"
              :error="veeErrors.has('code')"
              :data-vv-as="$t('StoreGroups.popupForm_Field_Code')"
              v-validate="'max:128|verify_code'"
              id="input-store-groups-popup-field-code"
              v-model="item.code"
              :isDelayInput="true"
            )

            Button(
              size="small"
              variant= "icon loading"
              id="btn-store-groups-popup-loading-code",
              tabindex="-1",
              v-show="isPendingCode"
            )
              Loading(theme="disable")

            Button(
              size="small"
              id="btn-store-groups-popup-ok-code",
              variant= "icon check"
              tabindex="-1",
              v-show="isValidateCode",
              iconName="icon-check",
              iconClass="icon-check",
              :justIcon="true"
            )

            showValidateError(
              v-show="veeErrors.has('code')"
              :errorName="veeErrors.first('code')"
              id="validate-store-groups-code-message"
            )

        div.Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_TaxNumber') }}
          .control
            customTextInput(
              name="taxNumber"
              :placeholder="$t('Stores.popupForm_Field_TaxNumber_Placeholder')"
              :error="veeErrors.has('taxNumber')"
              :data-vv-as="$t('Stores.popupForm_Field_TaxNumber')"
              v-validate="'min:5|max:20'"
              id="input-store-group-popup-field-tax-number"
              v-model="item.taxNumber"
            )

            showValidateError(
              v-show="veeErrors.has('taxNumber')"
              :errorName="veeErrors.first('taxNumber')"
            )
        .Form-item.name
          label.Form-item-label {{ $t('Global.timeZone') }}
          .control
            customSelect(
              name="timeZone"
              v-model="item.timeZoneKey"
              :optionData="TimeZoneList"
              optionTitle="value"
              selectedEmitValue="id"
              componentName="time-zone-select"
              :useCustomSearchKey="true"
              :isDefaultTextActive="false"
              :defaultTitle="$t('Register.formFieldTimeZoneSelect')"
              :data-vv-as="$t('Global.timeZone')"
              :isSearchActive="true"
              v-validate="'required'"
              :error="veeErrors.has('timeZone')"
            )

            showValidateError(
              v-show="veeErrors.has('timeZone')"
              :errorName="veeErrors.first('timeZone')"
            )
        .Form-item.name
          .control
            label.Form-item-label {{ $t('Global.currency') }}
            customSelect(
              name="currency"
              v-model="item.baseCurrencyId"
              :optionData="CurrencyList"
              optionTitle="name"
              selectedEmitValue="id"
              componentName="currency-select"
              :isDefaultTextActive="false"
              :isDisabled="isEdit"
              :defaultTitle="$t('Register.formFieldCurrencySelect')"
              :data-vv-as="$t('Global.currency')"
              :isSearchActive="true"
              :error="veeErrors.has('currency')"
            )

            showValidateError(
              v-show="veeErrors.has('currency')"
              :errorName="veeErrors.first('currency')"
            )

        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_TaxOfficeName') }}

          .control
            customTextInput(
              name="taxOfficeName"
              :error="veeErrors.has('taxOfficeName')"
              id="input-store-group-popup-field-tax-office-name"
              v-model="item.taxOfficeName"
            )
        
        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_Address') }}

          .address
            customTextInput(
              name="address"
              :placeholder="$t('Stores.popupForm_Field_Address_Placeholder')"
              :error="veeErrors.has('address')"
              :data-vv-as="$t('Stores.popupForm_Field_Address')"
              v-validate="'max:512'"
              id="input-store-popup-field-address"
              v-model="item.address"
            )
            showValidateError(
              v-show="veeErrors.has('address')"
              :errorName="veeErrors.first('address')"
            )

            CustomCheckbox.m-top-10(
              id="store-location-set`"
              :label="$t('Stores.formField_useMapForLocation')"
              v-model="useMap"
            )
        
            Modal.ListModal(
              :isOpen="useMap",
              @closeModal="useMap = false"
            )
              template(slot="title")
                | {{ $t('Stores.formField_selectLocation') }}
              template(slot="content")   
                GoogleMap(:apiKey="googleMapsApiKey" @change-address="getAddress" :language="userLangugeIsoCode" :autocomplete="true")
              template(slot="footer")  
                Button(
                primary
                size="large"
                variant="full"
                id="button-select-location-modal"
                @click="setAddress")
                  span {{ $t('Stores.selectAddress') }} 

        
        .Form-item
          label.Form-item-label {{ $t('Stores.popupForm_Field_PostalCode') }}

          .control
            customTextInput(
              name="postalCode"
              :error="veeErrors.has('postalCode')"
              id="input-store-group-popup-field-address-postal-code"
              v-model="item.postalCode"
            )
          

    template(
      id="footer-store-group-form"
      slot="footer"
    )

      template(
        v-if="isEdit"
      )

        Button(
          primary,
          size="large"
          variant= "full"
          id="btn-submit-store-group-form-edit"
          type="submit"
          form="form-store-group"
          :disabled="isLoading"
          :isLoading="isLoading"
        )
          span(v-show="!isLoading") {{ $t('StoreGroups.popupForm_Button_Edit') }}

      template(
        v-else
      )
        Button(
          primary,
          size="large"
          variant= "full",
          id="btn-submit-store-group-form-new"
          type="submit",
          form="form-store-group",
          :disabled="isLoading"
          :isLoading="isLoading"
        )
          span(v-show="!isLoading") {{ $t('StoreGroups.popupForm_Button_Create') }}
</template>

<script>
import { omit, vueWaitLoader } from '@/utils/baseOperations'
import { mapFields, Validator } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import customSelect from '@/view/global/custom-select'
import GoogleMap from '@/view/global/google-map'

export default {
  name: 'StoreGroupForm',

  components: {
    customSelect,
    GoogleMap
  },

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      item: {
        id: null,
        name: null,
        code: '',
        taxNumber: null,
        timeZoneKey: null,
        baseCurrencyId: null,
        address: null,
        postalCode: null,
        taxOfficeName: null
      },
      useMap: false,
      fullAddress: null,
      userLangugeIsoCode: null
    }
  },

  created () {
    const self = this

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value,
            Id: self.$route.params.id
          }
          self.checkStoreGroupName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    Validator.extend('verify_code', {
      // Custom validation message

      getMessage: () => this.$t('StoreGroups.Validation_DoubleStoreCodeError'),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let params
        if (this.isEdit) {
          params = {
            Code: this.item.code,
            Id: this.$route.params.id,
            TaxNumber: this.$route.params.taxNumber
          }
        } else {
          params = {
            Code: this.item.code
          }
        }
        self.checkStoreGroupCode(params)
          .then(res => {
            if (res) {
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
      })
    })

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => this.$t('StoreGroups.Validation_DoubleStoreGroupsError')
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)
  },

  async mounted () {
    await this.getUTCList()
    await this.getCurrencyList()
    this.item.timeZoneKey = this.Tenant.timeZoneKey
    this.item.baseCurrencyId = this.Tenant.baseCurrencyId
    this.userLangugeIsoCode = this.Languages.find(i => i.id = this.UserInfo.languageId).isoCode.split('-')[0]
    if (this.isEdit) this.init()
  },

  computed: {
    ...mapGetters('Global', [
      'TimeZoneList',
      'CurrencyList',
      'Languages'
    ]),

    ...mapGetters('Settings', ['UserInfo']),

    ...mapGetters('Auth', ['Tenant']),

    ...mapFields({
      flagsName: 'name',
      flagsCode: 'code'
    }),

    isLoading () {
      return this.$wait.is(['updateStoreGroup', 'createStoreGroup', 'storesGroupHeader'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isPendingCode () {
      return this.flagsCode.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return this.flagsName.valid && this.item.name !== '' && this.item.name !== null && !this.isPendingName
      }
    },

    googleMapsApiKey () {
      return window.VUE_APP_GOOGLE_MAPS_API_KEY || process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    },

    isValidateCode () {
      if (this.flagsCode.required) {
        return this.flagsCode.valid && !this.isPendingCode
      } else {
        return this.flagsCode.valid && this.item.code !== '' && this.item.code !== null && !this.isPendingCode
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Global', [
      'getUTCList',
      'getCurrencyList'
    ]),

    ...mapActions('StoreGroups', [
      'getStoreGroupList',
      'getStoreGroup',
      'createStoreGroup',
      'updateStoreGroup',
      'deleteStoreGroup',
      'checkStoreGroupName',
      'checkStoreGroupCode'
    ]),

    async init () {
      let item = this.$route.params.item
      if (!item) {
        let itemId = this.$route.params.id
        await this.getStoreGroup(itemId).then(res => {
          if (res.status === 200) {
            item = res.data.storeGroup
          }
        })
      }
      this.item = item
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    getAddress (data) {
      this.fullAddress = data.address
      this.item.postalCode = data.info.address_components.find(i => i.types[0] === 'postal_code').short_name
    },

    setAddress () {
      this.useMap = false
      this.item.address = this.fullAddress
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    onSubmitStoreGroupForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        // form error
        if (!result) return
        if (this.isEdit) return this.submitEdit()
        return this.submitNew()
      })
    }, 'storesGroupHeader'),

    async submitNew () {
      let item = omit(this.item, ['id'])
      await this.createStoreGroup(item).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: item.name })
          this.notifyShow({ message })
          this.getStoreGroupList({pageNumber: 1, pageSize: 9999})
          this.close()
        }
      })
    },

    async submitEdit () {
      await this.updateStoreGroup(this.item).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: this.item.name })
          this.notifyShow({ message })
          this.getStoreGroupList({pageNumber: 1, pageSize: 9999})
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .info-popover {
    display: inline-block;
    padding: 0;
    width: 16px;
    position: relative;

    &__popup {
      display: none;
      padding: 10px 20px;
      position: absolute;
      z-index: 2;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      max-width: 225px;
      white-space: pre;
      font-size: $font-size-small;
      background-color: white;
      border: 2px solid $color-warning;
      border-radius: $border-radius;
      box-shadow: 0 2px 15px rgba($color-warning, .5);

      &::after {
        content: "";
        position: absolute;
        top: -1.7px;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 3px;
        border-top: inherit;
        border-left: inherit;
        box-shadow: inherit;
        background: inherit;
        transform:
          rotate(45deg)
          translateX(-50%);
        clip-path: polygon(
          0% 110%,
          110% 0%,
          0% 0%
        );
      }
    }

    &__icon:hover + &__popup,
    &__icon:focus + &__popup {
      display: block;
    }
  }
  :deep() .select-dropdown-custom {
    &-options {
      &.upside {
        width: 100% !important;
      }
      &.normal {
        width: 100% !important;
      }
    } 
    &-selected {
      width: 100% !important;
      height: 54px !important;
      &-name {
        font-size: $font-size-bigger !important;
      }
    }
    .selected-name {
      font-size: $font-size-normal !important;
    }
  }

</style>
