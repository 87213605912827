import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { StoreGroups } from '../model'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    StoreGroupList: new StoreGroups(),
    ReleatedStoreGroupList: [],
    BaseRvcGroupList: [],
    ReleatedRvcGroupList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    storeGroupList: state => state.StoreGroupList.list,
    isStoreGroupsEmpty: state => state.StoreGroupList.length === 0,
    releatedStoreGroupList: state => state.ReleatedStoreGroupList,
    baseRvcGroupList: state => state.BaseRvcGroupList,
    releatedRvcGroupList: state => state.ReleatedRvcGroupList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getStoreGroupList({ commit, state }, payload) {
      payload.pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const loadingName = 'getStoreGroupList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('StoreGroup/all', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_STOREGROUP_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getStoreRvcGroupList({ commit, dispatch }, params) {
      const loadingName = 'getStoreRvcGroupList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Pos/revenuecenter/filter', params)
        },
        success: result => {
          commit('SET_STORE_RVC_GROUP_LIST', result.data.revenueCenterList)
          return result
        }
      })
      return results
    },

    async getStoreGroup({ dispatch }, Id) {
      const loadingName = 'getStoreGroup'
      const params = { Id }
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('StoreGroup', { params })
        },
        success: result => result
      })
      return results
    },

    async fetchStoreGroupByStoreName({ commit }, StoreId) {
      const params = {
        StoreId
      }
      const results = await requestHandler({
        title: 'fetchStoreGroupByStoreName',
        methodName: 'fetchStoreGroupByStoreName',
        method: () => {
          return HTTP.get('StoreGroup/getbystore', { params })
        },
        success: result => result
      })
      return results
    },

    async createStoreGroup({ dispatch }, item) {
      const loadingName = 'createStoreGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('StoreGroup', item)
        },
        success: result => result
      })
      return results
    },

    async updateStoreGroup({ dispatch }, item) {
      const loadingName = 'updateStoreGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('StoreGroup', item)
        },
        success: result => result
      })
      return results
    },

    async deleteStoreGroup({ dispatch }, Id) {
      const loadingName = 'deleteStoreGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('StoreGroup', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async checkStoreGroupName({ commit, dispatch }, params) {
      const loadingName = 'checkStoreGroupName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('StoreGroup/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async checkStoreGroupCode({ commit, dispatch }, params) {
      const loadingName = 'checkStoreGroupCode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('StoreGroup/check/code', { params })
        },
        success: result => result
      })
      return results
    },

    async getReleatedGroupList({ commit, dispatch }, params) {
      const loadingName = 'getReleatedGroupList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('StoreGroup/relatedstoregroups', { params })
        },
        success: result => {
          commit('SET_RELEATEDSTOREGROUP_LIST', result.data.storeGroupList)
          return result
        }
      })
      return results
    },

    async getReleatedRvcGroupList({ commit, dispatch }, params) {
      const loadingName = 'getReleatedRvcGroupList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Pos/revenuecenter/filter', params)
        },
        success: result => {
          commit('SET_RELEATED_RVC_GROUP_LIST', result.data.revenueCenterList)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.StoreGroupList = new StoreGroups()
      state.Page.number = 1
      state.Page.size = 20
      state.Page.total = 0
    },

    SET_STOREGROUP_LIST(state, data) {
      state.StoreGroupList.addItems(data.storeGroupList)
      state.Page.number = data.pageNumber
      state.Page.total = data.totalCount
      state.Page.size = data.pageSize
    },

    SET_RELEATEDSTOREGROUP_LIST(state, list) {
      state.ReleatedStoreGroupList = list
    },

    SET_STORE_RVC_GROUP_LIST(state, list) {
      state.BaseRvcGroupList = list
    },

    SET_RELEATED_RVC_GROUP_LIST(state, list) {
      state.ReleatedRvcGroupList = list
    }
  }
}
